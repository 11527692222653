<template>
  <article class="workReport" v-loading="loading">
    <RouterNav :name="name"></RouterNav>
    <header class="header">
      <div class="label">当前查看：</div>
      <div class="tag">{{className}}</div>
      <div class="tag" v-if="dateType">{{dateType === 1 ? '近一周' : '近一月'}}</div>
      <div class="tag" v-else>{{startTime}}至{{endTime}}</div>
    </header>

    <div class="workReport-container">
      <Report
        ref="report"
        :classId="classId"
        :dateType="dateType"
        :startTime="startTime"
        :endTime="endTime"
      ></Report>
    </div>

  </article>
</template>

<script>
import RouterNav from '@/components/RouterNav'
import Report from '../components/Report'
export default {
  name: 'classWorkReport',
  components: {
    RouterNav,
    Report
  },
  data () {
    return {
      loading: false,
      classId: '',
      className: '',
      dateType: 1,
      startTime: '',
      endTime: '',
      name: ''
    }
  },
  mounted () {
    this.$store.commit('includeAdd', 'classWorkReport')
    const { query } = this.$route
    this.classId = query.classId
    this.className = query.className
    this.dateType = query.dateType
    this.startTime = query.startTime
    this.endTime = query.endTime
    this.name = query.name
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
  .header {
    @include flex(flex-start);
    background-color: #fff;
    height: 56px;
    padding: 0 28px;
    margin-bottom: 10px;
    font-size: 14px;
    .label {
      margin-right: 14px;
    }
    .tag {
      background-color: #EAF4FD;
      height: 26px;
      line-height: 26px;
      padding: 0 14px;
      border-radius: 13px;
      margin-right: 10px;
      color: #309AF2;
    }
  }
  .workReport-container {
    height: calc(100vh - 230px);
    overflow: auto;
  }
  .section {
    background-color: #fff;
    margin-bottom: 10px;
    overflow: hidden;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 66px;
      padding-right: 28px;
      @include font;
      font-weight: bold;
      &:hover {
        .hidden {
          visibility: visible;
        }
      }
      .left {
        display: flex;
        align-items: center;
      }
      .line {
        background-color: #309AF2;
        width: 4px;
        height: 14px;
        margin-right: 24px;
      }
      .icon {
        background-image: url("../../../assets/icon-2.png");
        background-size: 100% 100%;
        width: 10px;
        height: 10px;
        margin-left: 20px;
      }
      .tips {
        background-color: #EAF4FD;
        height: 34px;
        line-height: 34px;
        padding: 0 20px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: normal;
      }
      .right {
        display: flex;
        align-items: center;
        color: #309AF2;
        font-size: 14px;
      }
      .btn {
        background: #EAF4FD url("../../../assets/z-1.png") no-repeat center center;
        background-size: 11px 6px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-left: 10px;
        cursor: pointer;
        transition: all .3s;
      }
      .loading-btn {
        background: #EAF4FD url("../../../assets/loading.png") no-repeat center center;
        background-size: 16px 16px;
        animation: rotate .5s linear infinite;
      }
    }
    .content {
      display: flex;
      padding: 0 28px 20px;
      .wrapper {
        flex: 1;
        padding: 10px 0;
        border: 1px solid #E5E5E5;
        .gauge {
          width: 180px;
          height: 180px;
          margin: 0 auto;
        }
        .name {
          padding-left: 26px;
          margin-bottom: 12px;
          font-size: 14px;
          font-weight: bold;
        }
        .bar-tips {
          color: #999999;
          font-size: 10px;
          position: absolute;
          right: 30px;
          top: 21px;
        }
      }
    }
    .sub {
      border-top: 2px solid #1DC060;
      .sub-title {
        display: inline-block;
        background: linear-gradient(to right, #1DC060, #3EE199);
        height: 28px;
        line-height: 28px;
        margin-left: 28px;
        padding: 0 14px;
        border-radius: 0 0 8px 8px;
        color: #fff;
        font-size: 14px;
        .mark {
          width: 14px;
          height: 14px;
          margin-left: 6px;
          vertical-align: middle;
        }
      }
      .sub-title-default {
        background: linear-gradient(to right, #309AF2, #5ECAFA);
      }
      .sub-title-warn {
        background: linear-gradient(to right, #FF6F5E, #FF6F5E);
      }
    }
    .table {
      padding: 0 28px 20px;
      .link {
        color: #309AF2;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .more-btn {
      @include flex;
      background-color: #F6F6F6;
      width: 120px;
      height: 32px;
      margin: 0 auto 40px;
      border-radius: 20px;
      color: #309AF2;
      font-size: 14px;
      cursor: pointer;
      img {
        width: 7px;
        height: 4px;
        margin-left: 4px;
        transition: all .3s;
      }
    }
  }
</style>
